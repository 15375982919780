/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { CustomerInfoService } from './services/customer-info.service';
import { HealthService } from './services/health.service';
import { SvgDataService } from './services/svg-data.service';
import { ActivationCodeService } from './services/activation-code.service';
import { BankAccountsService } from './services/bank-accounts.service';
import { InvoicesService } from './services/invoices.service';
import { PaymentTermsService } from './services/payment-terms.service';
import { TcRegistrationService } from './services/tc-registration.service';
import { TurnoversService } from './services/turnovers.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CustomerInfoService,
    HealthService,
    SvgDataService,
    ActivationCodeService,
    BankAccountsService,
    InvoicesService,
    PaymentTermsService,
    TcRegistrationService,
    TurnoversService,
    ApiConfiguration
  ],
})
export class TollCollectModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<TollCollectModule> {
    return {
      ngModule: TollCollectModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: TollCollectModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('TollCollectModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
