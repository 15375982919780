/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseBodyInvoiceDto } from '../../models/response-body-invoice-dto';

export interface GetAllInvoices$Params {
  sortBy?: string;
  sortOrder?: string;

/**
 * offset for pagination
 */
  offset?: number;

/**
 * limit for pagination
 */
  limit?: number;

/**
 * SVG Number like 34 or 55
 */
  svgId: string;

/**
 * SVG Number like 34 or 55
 */
  customerId: string;

/**
 * Total of invoice
 */
  grossAmount?: string;

/**
 * Date of invoice
 */
  invoiceDate?: string;

/**
 * Invoice number
 */
  invoiceNumber?: string;
}

export function getAllInvoices(http: HttpClient, rootUrl: string, params: GetAllInvoices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyInvoiceDto>> {
  const rb = new RequestBuilder(rootUrl, getAllInvoices.PATH, 'get');
  if (params) {
    rb.query('sortBy', params.sortBy, {"explode":false});
    rb.query('sortOrder', params.sortOrder, {"explode":false});
    rb.query('offset', params.offset, {"explode":false});
    rb.query('limit', params.limit, {"explode":false});
    rb.path('svgId', params.svgId, {});
    rb.path('customerId', params.customerId, {});
    rb.query('grossAmount', params.grossAmount, {"explode":false});
    rb.query('invoiceDate', params.invoiceDate, {"explode":false});
    rb.query('invoiceNumber', params.invoiceNumber, {"explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseBodyInvoiceDto>;
    })
  );
}

getAllInvoices.PATH = '/svgs/{svgId}/customers/{customerId}/invoices';
