/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getAllTcRegistrationOfCustomer } from '../fn/tc-registration/get-all-tc-registration-of-customer';
import { GetAllTcRegistrationOfCustomer$Params } from '../fn/tc-registration/get-all-tc-registration-of-customer';
import { getAllTcRegistrationsOfSvg } from '../fn/tc-registration/get-all-tc-registrations-of-svg';
import { GetAllTcRegistrationsOfSvg$Params } from '../fn/tc-registration/get-all-tc-registrations-of-svg';
import { getTcRegistration } from '../fn/tc-registration/get-tc-registration';
import { GetTcRegistration$Params } from '../fn/tc-registration/get-tc-registration';
import { TcRegistrationDto } from '../models/tc-registration-dto';

@Injectable({ providedIn: 'root' })
export class TcRegistrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllTcRegistrationOfCustomer()` */
  static readonly GetAllTcRegistrationOfCustomerPath = '/svgs/{svgId}/customers/{customerId}/tc-registrations';

  /**
   * Get paginated tc-registrations of one Customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTcRegistrationOfCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTcRegistrationOfCustomer$Response(params: GetAllTcRegistrationOfCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcRegistrationDto>>> {
    return getAllTcRegistrationOfCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated tc-registrations of one Customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTcRegistrationOfCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTcRegistrationOfCustomer(params: GetAllTcRegistrationOfCustomer$Params, context?: HttpContext): Observable<Array<TcRegistrationDto>> {
    return this.getAllTcRegistrationOfCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcRegistrationDto>>): Array<TcRegistrationDto> => r.body)
    );
  }

  /** Path part for operation `getTcRegistration()` */
  static readonly GetTcRegistrationPath = '/svgs/{svgId}/customers/{customerId}/tc-registrations/{tcPaymentNumber}';

  /**
   * get tc-registration for customer by tcpayment number (svg id / customerId).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTcRegistration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTcRegistration$Response(params: GetTcRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
    return getTcRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * get tc-registration for customer by tcpayment number (svg id / customerId).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTcRegistration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTcRegistration(params: GetTcRegistration$Params, context?: HttpContext): Observable<TcRegistrationDto> {
    return this.getTcRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcRegistrationDto>): TcRegistrationDto => r.body)
    );
  }

  /** Path part for operation `getAllTcRegistrationsOfSvg()` */
  static readonly GetAllTcRegistrationsOfSvgPath = '/svgs/{svgId}/tc-registrations';

  /**
   * Get paginated tc-registrations of one SVG.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTcRegistrationsOfSvg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTcRegistrationsOfSvg$Response(params: GetAllTcRegistrationsOfSvg$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcRegistrationDto>>> {
    return getAllTcRegistrationsOfSvg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated tc-registrations of one SVG.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTcRegistrationsOfSvg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTcRegistrationsOfSvg(params: GetAllTcRegistrationsOfSvg$Params, context?: HttpContext): Observable<Array<TcRegistrationDto>> {
    return this.getAllTcRegistrationsOfSvg$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcRegistrationDto>>): Array<TcRegistrationDto> => r.body)
    );
  }

}
