/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createInvoice } from '../fn/invoices/create-invoice';
import { CreateInvoice$Params } from '../fn/invoices/create-invoice';
import { CreateInvoiceDocumentUrlResponseBody } from '../models/create-invoice-document-url-response-body';
import { createTemporaryInvoiceDownloadLink } from '../fn/invoices/create-temporary-invoice-download-link';
import { CreateTemporaryInvoiceDownloadLink$Params } from '../fn/invoices/create-temporary-invoice-download-link';
import { getAllInvoices } from '../fn/invoices/get-all-invoices';
import { GetAllInvoices$Params } from '../fn/invoices/get-all-invoices';
import { getInvoice } from '../fn/invoices/get-invoice';
import { GetInvoice$Params } from '../fn/invoices/get-invoice';
import { InvoiceDto } from '../models/invoice-dto';
import { ResponseBodyInvoiceDto } from '../models/response-body-invoice-dto';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllInvoices()` */
  static readonly GetAllInvoicesPath = '/svgs/{svgId}/customers/{customerId}/invoices';

  /**
   * Get list of customers invoices.
   *
   * List of customers invoices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllInvoices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInvoices$Response(params: GetAllInvoices$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseBodyInvoiceDto>> {
    return getAllInvoices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of customers invoices.
   *
   * List of customers invoices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllInvoices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInvoices(params: GetAllInvoices$Params, context?: HttpContext): Observable<ResponseBodyInvoiceDto> {
    return this.getAllInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseBodyInvoiceDto>): ResponseBodyInvoiceDto => r.body)
    );
  }

  /** Path part for operation `createInvoice()` */
  static readonly CreateInvoicePath = '/svgs/{svgId}/customers/{customerId}/invoices';

  /**
   * Create invoice.
   *
   * Create a new invoice for a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInvoice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInvoice$Response(params: CreateInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return createInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Create invoice.
   *
   * Create a new invoice for a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInvoice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInvoice(params: CreateInvoice$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.createInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /** Path part for operation `getInvoice()` */
  static readonly GetInvoicePath = '/svgs/{svgId}/customers/{customerId}/invoices/{id}';

  /**
   * Get a invoice.
   *
   * Get a customers invoice by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoice$Response(params?: GetInvoice$Params, context?: HttpContext): Observable<StrictHttpResponse<InvoiceDto>> {
    return getInvoice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a invoice.
   *
   * Get a customers invoice by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoice(params?: GetInvoice$Params, context?: HttpContext): Observable<InvoiceDto> {
    return this.getInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceDto>): InvoiceDto => r.body)
    );
  }

  /** Path part for operation `createTemporaryInvoiceDownloadLink()` */
  static readonly CreateTemporaryInvoiceDownloadLinkPath = '/svgs/{svgId}/customers/{customerId}/invoices/{id}/download';

  /**
   * Create a download urls for invoice.
   *
   * Create a download url by attaching a sas token to the given blob url. The generated Url is not persisted. The sas token has an expiration, so this url is only temporary usable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemporaryInvoiceDownloadLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTemporaryInvoiceDownloadLink$Response(params: CreateTemporaryInvoiceDownloadLink$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateInvoiceDocumentUrlResponseBody>> {
    return createTemporaryInvoiceDownloadLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a download urls for invoice.
   *
   * Create a download url by attaching a sas token to the given blob url. The generated Url is not persisted. The sas token has an expiration, so this url is only temporary usable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemporaryInvoiceDownloadLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTemporaryInvoiceDownloadLink(params: CreateTemporaryInvoiceDownloadLink$Params, context?: HttpContext): Observable<CreateInvoiceDocumentUrlResponseBody> {
    return this.createTemporaryInvoiceDownloadLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateInvoiceDocumentUrlResponseBody>): CreateInvoiceDocumentUrlResponseBody => r.body)
    );
  }

}
