/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BankAccountDto } from '../models/bank-account-dto';
import { createBankAccount } from '../fn/bank-accounts/create-bank-account';
import { CreateBankAccount$Params } from '../fn/bank-accounts/create-bank-account';
import { deleteBankAccount } from '../fn/bank-accounts/delete-bank-account';
import { DeleteBankAccount$Params } from '../fn/bank-accounts/delete-bank-account';
import { getAllBankAccounts } from '../fn/bank-accounts/get-all-bank-accounts';
import { GetAllBankAccounts$Params } from '../fn/bank-accounts/get-all-bank-accounts';
import { getBankAccount } from '../fn/bank-accounts/get-bank-account';
import { GetBankAccount$Params } from '../fn/bank-accounts/get-bank-account';

@Injectable({ providedIn: 'root' })
export class BankAccountsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllBankAccounts()` */
  static readonly GetAllBankAccountsPath = '/svgs/{svgId}/customers/{customerId}/bank-accounts';

  /**
   * Get list of customers bank accounts.
   *
   * List of customers bank accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBankAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBankAccounts$Response(params: GetAllBankAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BankAccountDto>>> {
    return getAllBankAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of customers bank accounts.
   *
   * List of customers bank accounts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBankAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBankAccounts(params: GetAllBankAccounts$Params, context?: HttpContext): Observable<Array<BankAccountDto>> {
    return this.getAllBankAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BankAccountDto>>): Array<BankAccountDto> => r.body)
    );
  }

  /** Path part for operation `createBankAccount()` */
  static readonly CreateBankAccountPath = '/svgs/{svgId}/customers/{customerId}/bank-accounts';

  /**
   * Create bank account.
   *
   * Create a new bank account for a customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBankAccount$Response(params: CreateBankAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<BankAccountDto>> {
    return createBankAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bank account.
   *
   * Create a new bank account for a customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBankAccount(params: CreateBankAccount$Params, context?: HttpContext): Observable<BankAccountDto> {
    return this.createBankAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankAccountDto>): BankAccountDto => r.body)
    );
  }

  /** Path part for operation `getBankAccount()` */
  static readonly GetBankAccountPath = '/svgs/{svgId}/customers/{customerId}/bank-accounts/{id}';

  /**
   * Get a bank account.
   *
   * Get a customers bank account by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBankAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankAccount$Response(params: GetBankAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<BankAccountDto>> {
    return getBankAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a bank account.
   *
   * Get a customers bank account by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBankAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankAccount(params: GetBankAccount$Params, context?: HttpContext): Observable<BankAccountDto> {
    return this.getBankAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankAccountDto>): BankAccountDto => r.body)
    );
  }

  /** Path part for operation `deleteBankAccount()` */
  static readonly DeleteBankAccountPath = '/svgs/{svgId}/customers/{customerId}/bank-accounts/{id}';

  /**
   * Delte bank account.
   *
   * Delete customers bank account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBankAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAccount$Response(params: DeleteBankAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBankAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Delte bank account.
   *
   * Delete customers bank account
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBankAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAccount(params: DeleteBankAccount$Params, context?: HttpContext): Observable<void> {
    return this.deleteBankAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
