/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TcRegistrationDto } from '../../models/tc-registration-dto';

export interface GetTcRegistration$Params {

/**
 * SVG Number like 34 or 55
 */
  svgId: string;
  customerId: string;

/**
 * 16 digits with check digit, prefix 7056
 */
  tcPaymentNumber: string;
}

export function getTcRegistration(http: HttpClient, rootUrl: string, params: GetTcRegistration$Params, context?: HttpContext): Observable<StrictHttpResponse<TcRegistrationDto>> {
  const rb = new RequestBuilder(rootUrl, getTcRegistration.PATH, 'get');
  if (params) {
    rb.path('svgId', params.svgId, {});
    rb.path('customerId', params.customerId, {});
    rb.path('tcPaymentNumber', params.tcPaymentNumber, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TcRegistrationDto>;
    })
  );
}

getTcRegistration.PATH = '/svgs/{svgId}/customers/{customerId}/tc-registrations/{tcPaymentNumber}';
