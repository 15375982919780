/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CodeDto } from '../models/code-dto';
import { createActivationCode } from '../fn/activation-code/create-activation-code';
import { CreateActivationCode$Params } from '../fn/activation-code/create-activation-code';

@Injectable({ providedIn: 'root' })
export class ActivationCodeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createActivationCode()` */
  static readonly CreateActivationCodePath = '/svgs/{svgId}/customers/{customerId}/activation-code';

  /**
   * Creates a fresh activation code for a tc payment number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createActivationCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  createActivationCode$Response(params: CreateActivationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<CodeDto>> {
    return createActivationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a fresh activation code for a tc payment number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createActivationCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createActivationCode(params: CreateActivationCode$Params, context?: HttpContext): Observable<CodeDto> {
    return this.createActivationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<CodeDto>): CodeDto => r.body)
    );
  }

}
